import styled from 'styled-components';

export const Label = styled.label`
    /* display: flex;
    flex-direction: column;
    gap: 0.8rem;  // Increased gap slightly for larger font-size
    width: 100%;

    @media (min-width: 768px) {
        width: 48%;
    } */
`;
